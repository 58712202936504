const Banner = ({data, speed = 2000}) => {
    console.log(data);
    return (
        <div className="inner">
            <div className="wrapper">
                <section style={{"--speed": `${speed}ms`}}>
                    {data.map((d, index) => (
                        <div key={index} className="testimonial">
                            <div className="testimonial-image">
                                {" "}
                                <img src={d.img} alt=""/>{" "}
                            </div>
                            <div className="testimonial-content">
                                <p>"{d.text}"</p>
                                <div className="testimonial-meta"> - {d.name} </div>
                            </div>
                        </div>
                    ))}
                </section>
                <section style={{"--speed": `${speed}ms`}}>
                    {data.map((d, index) => (
                        <div key={index} className="testimonial">
                            <div className="testimonial-image">
                                {" "}
                                <img src={d.img} alt=""/>{" "}
                            </div>
                            <div className="testimonial-content">
                                <p>"{d.text}"</p>
                                <div className="testimonial-meta"> - {d.name} </div>
                            </div>
                        </div>
                    ))}
                </section>
                <section style={{"--speed": `${speed}ms`}}>
                    {data.map((d, index) => (
                        <div key={index} className="testimonial">
                            <div className="testimonial-image">
                                {" "}
                                <img src={d.img} alt=""/>{" "}
                            </div>
                            <div className="testimonial-content">
                                <p>"{d.text}"</p>
                                <div className="testimonial-meta"> - {d.name} </div>
                            </div>
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export {Banner};
