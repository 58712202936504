import React from "react";
import {Banner} from "./banner";

export const Testimonials = (props) => {
    console.log(props.data);
    return (
        <div id="testimonials">
            <div className="container">
                <div className="text-center">
                    <h2>Devenir Parternaire</h2>
                </div>
                {props.data?.sponsors && (
                    <div>
                        <div className={"container"}>
                            <h3>Platinium</h3>
                            {props.data?.sponsors["Platinium"].map((d, i) => (
                                <div className="col-md-6 testimonial-logo ">
                                    {" "}
                                    <img src={d.logo} className={"Platinium"} alt=""/>{" "}
                                </div>
                            ))}
                        </div>
                        <div className={"container"}>
                            <h3>Or</h3>
                            {props.data?.sponsors["Or"].map((d, i) => (
                                <div className="col-md-4 testimonial-logo ">
                                    {" "}
                                    <img src={d.logo} className={"Or"} alt=""/>{" "}
                                </div>
                            ))}
                        </div>
                        <div className={"container"}>
                            <h3>Argent</h3>
                            {props.data?.sponsors["Argent"].map((d, i) => (
                                <div className="col-md-2 testimonial-logo ">
                                    {" "}
                                    <img src={d.logo} className={"Argent"} alt=""/>{" "}
                                </div>
                            ))}
                        </div>
                        <div className={"container"}>
                            <h3>Bronze</h3>
                            {props.data?.sponsors["Bronze"].map((d, i) => (
                                <div className="col-md-1 testimonial-logo ">
                                    {" "}
                                    <img src={d.logo} className={"Bronze"} alt=""/>{" "}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className={"text-center"}>
                    <a
                        href="/file/plan-commandite-connect-hacks.pdf"
                        target="_blank"
                        className="btn btn-custom btn-lg page-scroll"
                    >
                        Plan de commandite
                    </a>
                </div>
            </div>
            {props.data?.testimonials ? <Banner data={props.data.testimonials} speed={30000}/> : "Loading"}
        </div>
    );
};
