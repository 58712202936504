import React from "react";

export const Image = ({ title, smallImage }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        {/*<a href={largeImage} title={title} data-lightbox-gallery="gallery1">*/}
          <img src={smallImage} className="img-responsive" alt={title} />{" "}
        {/*</a>{" "}*/}
      </div>
    </div>
  );
};
