import React from "react";

export const Team = (props) => {
    return (
        <div id="team" className="text-center">
            <div className="container">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>Rencontre l'équipe</h2>
                    <p>
                        Découvrez notre équipe dévouée qui travaille avec passion pour réaliser notre mission.
                    </p>
                </div>
                <div id="row">
                    {props.data
                        ? props.data.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 col-md-offset-2 col-sm-offset-3 team">
                                <div className="thumbnail">
                                    {" "}
                                    <img src={d.img} alt="..." className="team-img"/>
                                    <div className="caption">
                                        <h4>{d.name}</h4>
                                        <p>{d.job}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
            </div>
        </div>
    );
};
